<template>
  <span v-if="data.item.type === 'sync'">
    <span>Aktuell: {{ data.item.command_parameter / 1000 }} - (KByte/s)</span>
    <SyncSpeed :syncSpeed="data.item.command_parameter / 1000" class="w-75 mt-2 mb-2"
               @sync-speed-invalid="disableButton" @sync-speed-changed="parameterChange"/>
  </span>
  <span v-else-if="data.item.type === 'hostname'">
    <HostnameCheck :pcUuid="pcUuid" :commandParameter="data.item.command_parameter"
                   @hostname-changed="parameterChange" @hostname-checked="hostnameChecked"/>
  </span>
  <span v-else-if="data.item.type === 'install'">Installieren</span>
  <span v-else-if="data.item.type === 'deinstall'">Deinstallieren</span>
  <span v-else-if="data.item.command_parameter">{{ data.item.command_parameter }}</span>
  <span v-else>-</span>
</template>

<script>
import SyncSpeed from "@/components/lists/computerList/tasks/utility/SyncSpeed.vue";
import HostnameCheck from "@/components/lists/computerList/tasks/editTaskModal/HostnameCheck.vue";

export default {
  name: "MetaData",
  props: ['data', 'pcUuid'],
  components: {
    HostnameCheck,
    SyncSpeed
  },
  methods: {
    disableButton(data) {
      this.$emit('button-disabled', data)
    },
    hostnameChecked(data) {
      this.$emit('hostname-checked', data)
    },
    parameterChange(data) {
      this.$emit('command-parameter-changed', data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
