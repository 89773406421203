<template>
  <b-modal :id="'deleteModal-' + pcUuid" @show="loadCategories" @hidden="reset" centered>
    <template #modal-header="{ close }">
      <TaskModalHeader :close="close" description="PC - Löschen" :pcHostname="pcHostname"/>
    </template>
    <template #default>
      <b-form-group>
        <label>Grund:</label>
        <b-form-select v-model="selected" :options="deleteCategories">
          <template #first>
            <b-form-select-option :value="null" disabled>-- Bitte eine Option wählen --</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback :state="selectState">Bitte einen Grund anwählen!</b-form-invalid-feedback>

        <label class="mt-4">Begründung:</label>
        <b-form-textarea v-model="reason" placeholder="Begründung angeben..."/>
        <b-form-invalid-feedback :state="textAreaState">Bitte eine Begründung (min. 4 Zeichen) angeben.
        </b-form-invalid-feedback>
      </b-form-group>
    </template>
    <template #modal-footer>
      <div class="mx-auto">
        <b-button :variant="variantDanger" @click="deletePC" :disabled="disabledButton">
          <b-spinner v-show="loadingDeletePC" small/>
          <span> PC löschen </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {toastDanger} from "@/modules/status";
import {getRequest, putRequest} from "@/modules/requests";
import TaskModalHeader from "@/components/lists/computerList/tasks/utility/TaskModalHeader.vue";

export default {
  name: 'DeleteModal',
  props: ['pcUuid', 'pcHostname'],
  components: {TaskModalHeader},
  data() {
    return {
      deleteCategories: [],
      selected: null,
      reason: '',
      loadingDeletePC: false,
    }
  },
  methods: {
    reset() {
      this.deleteCategories = [];
      this.selected = null
      this.reason = ''
    },
    async loadCategories() {
      await getRequest('pc/delete/categories', null, this)
          .then((response) => {
            response.data.forEach((elem) => {
              this.deleteCategories.push({
                value: elem.id,
                text: elem.displayname,
                description: elem.description
              })
            })
          })
    },
    async deletePC() {
      let data = {
        pc_delete_category_id: this.selected,
        reason: this.reason
      }

      this.loadingDeletePC = true;
      this.$emit('close-details')

      await putRequest('pc/delete/' + this.pcUuid, data, this, 'Der PC wurde erfolgreich entfernt!')
          .then(() => {
            this.loadingDeletePC = false;
            this.$emit('refresh')
            this.$bvModal.hide('deleteModal-' + this.pcUuid)
          })
          .catch((error) => {
            if(error.response.data.errors.pc_uuid[0] === "The provided pc_uuid has installed licenses.") {
              toastDanger(this, 'Der PC konnte aufgrund von Lizenzen nicht gelöscht werden!');
            } else {
              toastDanger(this, 'Der PC konnte nicht gelöscht werden!');
            }
            this.loadingDeletePC = false
          })
    }
  },
  computed: {
    selectState() {
      return this.selected === null ? false : null
    },
    textAreaState() {
      return this.reason.length < 4 ? false : null
    },
    disabledButton() {
      return (this.textAreaState === false || this.selectState === false)
    },
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
