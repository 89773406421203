<template>
  <b-table :fields="table.fields" :items="[task]" striped small caption-top class="text-white">
    <template #cell(name)="data">
      {{ data.item.displayname }}
    </template>
    <template #cell(metaData)="data">
      <MetaData :data="data" :pcUuid="pcUuid" @button-disabled="metaDataCheck"
                @command-parameter-changed="commandParameterChange" @hostname-checked="hostnameChecked"/>
    </template>
    <template #cell(issuer)="data">
      {{ data.item.issuer }}
    </template>
    <template #cell(scheduledTime)="data">
      {{ data.item.time_execute_scheduled | datetime }}
    </template>
    <template #cell(newTime)>
      <div v-if="task.status === 'running' || (task.task_status && task.task_status.status === 'running')">
        -
      </div>
      <div v-else>
        <Datepicker @datepicker-changed="datepickerChange" @date-changed="dateChange" @datetime-invalid="datetimeCheck"/>
        <Timepicker class="mt-2" @time-changed="timeChange" @datetime-invalid="datetimeCheck"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import dayjs from "dayjs";
import MetaData from "@/components/lists/computerList/tasks/editTaskModal/MetaData.vue";
import Datepicker from "@/components/lists/computerList/tasks/utility/dateTime/Datepicker.vue";
import Timepicker from "@/components/lists/computerList/tasks/utility/dateTime/Timepicker.vue";

export default {
  name: "EditTable",
  props: ['task', 'pcUuid'],
  components: {
    Timepicker,
    Datepicker,
    MetaData
  },
  data() {
    return {
      table: {
        fields: [
          {key: 'name', label: 'Aktion'},
          {key: 'metaData', label: 'Weitere Informationen'},
          {key: 'scheduledTime', label: 'Geplante Startzeit'},
          {key: 'newTime', label: 'Neue Startzeit'},
          {key: 'issuer', label: 'Ersteller'},
        ]
      },
      datetime: {
        date: {
          selected: dayjs().format('YYYY-MM-DD'),
        },
        time: {
          selected: dayjs().format('HH:mm:ss'),
        }
      },
      dateInvalid: false,
      timeInvalid: false
    }
  },
  methods: {
    commandParameterChange(data) {
      this.$emit('command-parameter-changed', data)
    },
    metaDataCheck(data) {
      this.$emit('button-disabled', data)
    },
    hostnameChecked(data) {
      this.$emit('hostname-checked', data)
    },
    datetimeCheck(data) {
      if(data.type === 'time') {
        this.timeInvalid = data.value
        this.$emit('button-disabled', this.dateInvalid || this.timeInvalid)
      }
      else if(data.type === 'date') {
        this.dateInvalid = data.value
        this.$emit('button-disabled', this.dateInvalid || this.timeInvalid)
      }
    },
    timeChange(data) {
      this.datetime.time.selected = data;
      this.$emit('datetime', this.datetime);
    },
    dateChange(data) {
      this.datetime.date.selected = data;
      this.$emit('datetime', this.datetime);
    },
    datepickerChange(data) {
      this.datetime.date.selected = data;
      this.$emit('datetime', this.datetime);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
