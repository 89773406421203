<template>
  <div class="badge-fix">
    <b-badge v-if="computer.hardware"
             :style="'color: ' + computer.hardware.color_font + '; background-color: ' + computer.hardware.color_background">
      {{ computer.hardware.displayname }}
    </b-badge>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  name: "HardwareInformation",
  props: ['computer']
}
</script>

<style lang="scss" scoped>
</style>
