<template>
  <div class="badge-fix clickable">
    <b-badge v-if="computer.shutdown" :variant="variantSuccess" :id="'po-shutdown-' + computer.id">
      {{ computer.time_shutdown }}
    </b-badge>
    <b-badge v-else :variant="variantDanger" :id="'po-shutdown-' + computer.id">{{ computer.time_shutdown }}</b-badge>
    <b-popover :target="'po-shutdown-' + computer.id" placement="left" :show.sync="popoverShow" @show="onShow">
      <div>
        <b-form-checkbox v-model="shutdown" switch>Ausschalten?</b-form-checkbox>
        <b-form-select size="sm" class="mb-2" v-model="shutdownSelect.selected" :options="shutdownSelect.options"/>
        <b-button @click="onClose" size="sm" :variant="variantDanger" class="mr-1">Abbrechen</b-button>
        <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";

export default {
  name: "ShutdownControl",
  props: ['computer'],
  data() {
    return {
      shutdownSelect: {
        selected: null,
        options: [
          {value: '16:45', text: '16:45'},
          {value: '17:20', text: '17:20'},
          {value: '23:59', text: '23:59'},
        ]
      },
      shutdown: null,
      popoverShow: false,
    }
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    onShow() {
      this.shutdownSelect.selected = this.computer.time_shutdown
      this.shutdown = this.computer.shutdown
    },
    async onOk() {
      let data = {
        shutdown: this.shutdown,
        time_shutdown: this.shutdownSelect.selected,
      }

      await putRequest('shutdown-time/' + this.computer.pc_uuid, data, this, null)
          .then(() => {
            this.onClose()
            this.$emit('refresh')
          })
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
