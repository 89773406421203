<template>
  <div>
    <b-form-input v-model="hostname" type="text" placeholder="zB. MA-HOME-001" required @input="hostnameChanged"/>
    <b-form-invalid-feedback :state="hostnameState">Der Hostname muss noch geprüft werden!</b-form-invalid-feedback>
    <b-button class="mt-2" variant="primary" @click="validateNewHostname">
      <b-spinner v-show="loadingValidateHostname" small/>
      Hostname überprüfen
    </b-button>
  </div>
</template>

<script>
import {putRequest} from "@/modules/requests";
import {toastDanger} from "@/modules/status";

export default {
  name: "HostnameCheck",
  props: ['pcUuid', 'commandParameter'],
  data() {
    return {
      loadingValidateHostname: false,
      commandParam: this.commandParameter,
      hostname: this.commandParameter,
      hostnameChecked: true
    }
  },
  methods: {
    hostnameChanged() {
      if(this.hostname === this.commandParam) {
        this.hostnameChecked = true
        this.$emit('hostname-checked', this.hostnameChecked)
      }
      else {
        this.hostnameChecked = false
        this.$emit('hostname-checked', this.hostnameChecked)
      }
    },
    async validateNewHostname() {
      this.loadingValidateHostname = true;
      let data = {
        pc_uuid: this.pcUuid,
        hostname: this.hostname
      }
      await putRequest('tasks/hostname-check', data, this, null)
          .then(() => {
            this.commandParam = this.hostname
            this.hostnameChecked = true
            this.$emit('hostname-changed', this.hostname)
            this.$emit('hostname-checked', this.hostnameChecked)
            this.loadingValidateHostname = false
          })
          .catch((error) => {
            this.loadingValidateHostname = false
            toastDanger(this, error.response.data.errors.hostname[0]);
          })
    },
  },
  computed: {
    hostnameState() {
      if(this.hostname !== this.commandParam) {
        return false
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
