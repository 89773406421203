<template>
  <b-modal :id="'editTaskModal-' + pcUuid" centered size="xl" @hidden="reset">
    <template #modal-header="{ close }">
      <TaskModalHeader :close="close" description="Aktion - Editieren" :pcHostname="pcHostname"/>
    </template>
    <template #default>
      <EditTable :task="task" :pcUuid="pcUuid" @datetime="changeDatetime" @button-disabled="buttonCheck"
                 @command-parameter-changed="commandParameterChange" @hostname-checked="hostnameCheck"/>
    </template>
    <template #modal-footer>
      <div v-if="task.status === 'running' || (task.task_status && task.task_status.status === 'running')"
           class="mx-auto">
        <b-button :variant="variantSuccess" @click="changeSyncSpeed" :disabled="buttonDisabled">
          <b-spinner v-show="changeTaskLoading" small/>
          <span class="text-bold" v-show="!changeTaskLoading">Sync-Geschwindigkeit ändern</span>
        </b-button>
        <b-button :variant="variantDanger" class="ml-4" @click="stopSyncTask">
          <b-spinner v-show="removeTaskLoading" small/>
          <span class="text-bold" v-show="!removeTaskLoading">Sync stoppen</span>
        </b-button>
      </div>
      <div v-else class="mx-auto">
        <b-button :variant="variantSuccess" @click="updateTask" :disabled="buttonDisabled || !hostnameChecked">
          <b-spinner v-show="changeTaskLoading" small/>
          <span class="text-bold" v-show="!changeTaskLoading">Aktion ändern</span>
        </b-button>
        <b-button :variant="variantDanger" class="ml-4" @click="removeTask">
          <b-spinner v-show="removeTaskLoading" small/>
          <span class="text-bold" v-show="!removeTaskLoading">Aktion löschen</span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import EditTable from "@/components/lists/computerList/tasks/editTaskModal/EditTable.vue";
import TaskModalHeader from "@/components/lists/computerList/tasks/utility/TaskModalHeader.vue";

export default {
  name: 'EditTaskModal',
  props: ['task', 'pcUuid', 'pcHostname'],
  components: {
    EditTable,
    TaskModalHeader
  },
  data() {
    return {
      removeTaskLoading: false,
      changeTaskLoading: false,
      buttonDisabled: false,
      datetime: {
        date: {
          selected: dayjs().format('YYYY-MM-DD'),
        },
        time: {
          selected: dayjs().format('HH:mm:ss'),
        }
      },
      command_parameter: null,
      hostnameChecked: true
    }
  },
  methods: {
    reset() {
      this.command_parameter = null
    },
    buttonCheck(data) {
      this.buttonDisabled = data
    },
    hostnameCheck(data) {
      this.hostnameChecked = data
    },
    changeDatetime(data) {
      this.datetime = data;
    },
    commandParameterChange(data) {
      this.command_parameter = data
    },
    async updateTask() {
      this.changeTaskLoading = true;
      let data = {
        task_id: this.task.id,
        command_parameter: this.command_parameter,
        time_execute_scheduled: this.datetime.date.selected + ' ' + this.datetime.time.selected
      }

      await putRequest('tasks/update', data, this, 'Aktion erfolgreich geändert!')
          .then(() => {
            this.changeTaskLoading = false;
            this.$emit('refresh')
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
          .catch(() => {
            this.changeTaskLoading = false;
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
    },
    async removeTask() {
      this.removeTaskLoading = true;
      let data = {
        task_id: this.task.id
      }

      await putRequest('tasks/remove', data, this, 'Aktion erfolgreich entfernt!')
          .then(() => {
            this.removeTaskLoading = false;
            this.$emit('refresh')
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
          .catch(() => {
            this.removeTaskLoading = false;
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
    },
    async stopSyncTask() {
      this.removeTaskLoading = true;
      let data = {
        task_id: this.task.id,
        command_parameter: 'stop',
      }

      await putRequest('tasks/sync-update', data, this, 'Aktion erfolgreich gestoppt!')
          .then(() => {
            this.removeTaskLoading = false;
            this.$emit('refresh')
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
          .catch(() => {
            this.removeTaskLoading = false;
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
    },
    async changeSyncSpeed() {
      this.changeTaskLoading = true;
      let data = {
        task_id: this.task.id,
        command_parameter: this.command_parameter,
      }

      await putRequest('tasks/sync-update', data, this, 'Aktion erfolgreich geändert!')
          .then(() => {
            this.changeTaskLoading = false;
            this.$emit('refresh')
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
          .catch(() => {
            this.changeTaskLoading = false;
            this.$bvModal.hide('editTaskModal-' + this.pcUuid)
          })
    }
  },
  mounted() {
    if(this.task) {
      this.command_parameter = this.task.command_parameter
    }
  },
  computed: {
    ...mapGetters(['userColorAccessibility']),
    title() {
      if(this.method === 'edit') {
        return 'Kursdaten ändern'
      }
      return 'Kursdaten hinzufügen'
    },
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Modal';
</style>
