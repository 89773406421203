<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="headerClass">
    <template #header>
      <b-container fluid class="px-2 clickable">
        <b-row @click.self="getDetails">
          <b-col cols="auto" class="p-0" @click.self="getDetails">
            <ComputerOnline :computer="computer"/>
            <UserConnected :computer="computer"/>
            <WinUser :computer="computer"/>
            <CustomerAssigned :computer="computer"/>
          </b-col>
          <b-col cols="2" @click.self="getDetails">
            <b-row class="pl-2">
              <b-col cols="7" @click.self="getDetails" class="p-0">
                <HostnameControl :computer="computer" @close-details="() => { if(this.expand) {getDetails()}}"
                                 @refresh="refresh"/>
              </b-col>
              <b-col cols="5" @click.self="getDetails" class="p-0">
                <Clipboard :value="computer.ipv4_address" title="IPv4-Adresse kopieren"/>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" @click.self="getDetails">
            <HardwareInformation :computer="computer"/>
          </b-col>
          <b-col cols="1" @click.self="getDetails">
            <ImageInformation :computer="computer" @refresh="refresh"/>
          </b-col>
          <b-col cols="6" @click.self="getDetails">
            <TasksDisplay :computer="computer" @get-details="getDetails" @refresh="refresh"/>
          </b-col>
          <b-col cols="auto" class="p-0">
            <ShutdownControl :computer="computer" @refresh="refresh"/>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0" @click="getDetails">
            <ComputerWarnings :computer="computer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <ComputerDetails v-if="expand" :computer="computer" :singleView="false" @refresh="refresh"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import Clipboard from "@/components/utility/Clipboard.vue";
import TransitionExpand from "@/components/utility/TransitionExpand";
import WinUser from "@/components/lists/computerList/computerRow/WinUser";
import ComputerDetails from "@/components/details/computerDetails/ComputerDetails";
import TasksDisplay from "@/components/lists/computerList/computerRow/TasksDisplay";
import UserConnected from "@/components/lists/computerList/computerRow/UserConnected";
import ComputerOnline from "@/components/lists/computerList/computerRow/ComputerOnline";
import ShutdownControl from "@/components/lists/computerList/computerRow/ShutdownControl";
import HostnameControl from "@/components/lists/computerList/computerRow/HostnameControl";
import ComputerWarnings from "@/components/lists/computerList/computerRow/ComputerWarnings";
import ImageInformation from "@/components/lists/computerList/computerRow/ImageInformation";
import HardwareInformation from "@/components/lists/computerList/computerRow/HardwareInformation";
import CustomerAssigned from "@/components/lists/computerList/computerRow/CustomerAssigned";

export default {
  name: "ComputerRow",
  props: ['computer'],
  components: {
    Clipboard,
    ComputerDetails,
    ComputerWarnings,
    ShutdownControl,
    TasksDisplay,
    ImageInformation,
    HardwareInformation,
    HostnameControl,
    UserConnected,
    ComputerOnline,
    WinUser,
    TransitionExpand,
    CustomerAssigned,
  },
  data() {
    return {
      interval: null,
      expand: false,
    }
  },
  methods: {
    async getDetails() {
      if (!this.expand) {
        this.expand = true
      } else {
        clearInterval(this.interval)
        this.expand = false
        this.details = null
      }
    },
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    headerClass() {
      let classes = ['card-row', {rounded: !this.expand}]
      if (this.computer.warnings && !this.expand) {
        if (this.computer.warnings.includes('imaging')) {
          classes.push('card-row-primary');
        }
        if (this.computer.warnings.includes('customerDiff') || this.computer.warnings.includes('virus') || this.computer.warnings.includes('redundant')) {
          classes.push('card-row-danger')
        }
      }
      return classes
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
</style>
